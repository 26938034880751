let url2menu = JSON.parse(localStorage.getItem('url2menu')) || {}
let serials = JSON.parse(localStorage.getItem('serials')) || []

export function initMap(devs) {
    serials = []
    url2menu = { "new": "Add new"}
    devs.forEach(dev => {
        url2menu[dev._id] = dev.alias
        serials.push(dev.serialNumber)
    })
    localStorage.setItem('url2menu', JSON.stringify(url2menu))
    localStorage.setItem('serials', JSON.stringify(serials))
}

export function getName(id) {
    return url2menu[id]
}

export function aliasUsed(alias) {
    for(let id in url2menu)
        if(url2menu[id] === alias) 
            return true
    return false
}

export function serialUsed(sn) {
    return serials.indexOf(sn) > -1
}
