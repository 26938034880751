import {Container, Table} from "react-bootstrap"
import {Link} from "react-router-dom"
import {useState, useEffect} from 'react'
import Backend from "../Utils/Backend"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEdit} from '@fortawesome/free-solid-svg-icons'
import {initMap} from "../Utils/DeviceMap"

const Devices = ({edit}) => {
    const [devices, setDevices] = useState([])

    useEffect(() => {
        Backend.get(`${process.env.REACT_APP_BASE_URL}/xrayDevice`, res => {
            setDevices(res.data)
            initMap(res.data)
        })
    }, [])

    return (
        <Container>
            {edit && <div><Link to="/manage/new" className="btn btn-primary">Add new device</Link><br /><br /></div>}
            <div className="table">
                {devices.length > 0 && <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Alias</th>
                            <th>Serial number</th>
                            <th>Type</th>
                            {edit && <th>Edit</th>}
                            {!edit && <th>Registered at</th>}
                            {!edit && <th>Last login</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {devices.map((device) => (
                            <tr className="deviceList" id="tr" key={device._id}>
                                <td>
                                    <Link to={`/devices/${device._id}`}>
                                        {device.alias}
                                    </Link>
                                </td>
                                <td>{device.serialNumber}</td>
                                <td>{device.type}</td>
                                {edit && 
                                <td style={{textAlign: 'center'}}>
                                    <Link to={`/manage/${device._id}`}>
                                        <FontAwesomeIcon icon={faEdit} />
                                    </Link>
                                </td>}
                                {!edit && <td>{new Date(device.registeredAt).toLocaleString("hu-HU")}</td>}
                                {!edit && <td>{new Date(device.lastLogin).toLocaleString("hu-HU")}</td>}
                            </tr>
                        ))}
                    </tbody>
                </Table>}
            </div>
        </Container>
    )
}

export default Devices
