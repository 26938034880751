import { Route } from 'react-router-dom'
import Auth from '../Utils/Auth'

export default function ProtectedRoute({ component: Component, authed, ...rest }) {
  
  Auth.checkAuthentication()

  return (
    <Route
      {...rest}
      render={(props) => <Component {...props} {...rest} />}
    />
  )
}