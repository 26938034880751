import {Form, Button, Container, Card} from "react-bootstrap"
import {useForm} from "react-hook-form"
import Backend from "../Utils/Backend"
import {aliasUsed, serialUsed} from "../Utils/DeviceMap"
import {useHistory} from 'react-router-dom'

const AddNewDevice = () => {
    const {register, handleSubmit, setError, formState: { errors }} = useForm()
    const createUrl = `${process.env.REACT_APP_BASE_URL}/xrayDevice/create`
    const history = useHistory()

    const createDevice = (data) => {
        Backend.post(createUrl, {
            serialNumber: data.serial,
            alias: data.alias,
            type: data.type
        }, res => history.push("/manage"), err => {
            if(aliasUsed(data.alias))
                setError('alias', { type: 'custom', message: 'Alias must be unique!' })
            if(serialUsed(data.serial))
                setError('serial', { type: 'custom', message: 'Serial number must be unique!' })
        })
    }
    return (
        <Container>
            <Card>
                <Card.Header>
                    <div className="row">
                        <div className="col-lg-9">
                            <h4>Add new device</h4>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit(data => createDevice(data))}>
                        <Form.Group className="mb-3">
                            <Form.Label>Serial number</Form.Label>
                            <Form.Control type="text" placeholder="Enter serial numer..." {...register("serial", { required: "Serial number is required!" })} />
                            {errors.serial && <Form.Text style={{ color: 'red' }}>{errors.serial.message}</Form.Text>}
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Alias</Form.Label>
                            <Form.Control type="text" placeholder="Enter alias..." {...register("alias", { required: "Alias is required!" })} />
                            {errors.alias && <Form.Text style={{ color: 'red' }}>{errors.alias.message}</Form.Text>}
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Type</Form.Label>
                            <Form.Select {...register("type", { required: "Device type is required!" })}>
                                <option value="">Choose device type...</option>
                                <option value="Cobra">Cobra</option>
                                <option value="F100">F100</option>
                                <option value="F200">F200</option>
                                <option value="F300">F300</option>
                                <option value="F400">F400</option>
                                <option value="C100">C100</option>
                                <option value="C200">C200</option>
                                <option value="C300">C300</option>
                                <option value="C400">C400</option>
                            </Form.Select>
                            {errors.type && <Form.Text style={{ color: 'red' }}>{errors.type.message}</Form.Text>}
                        </Form.Group>
                        <br />
                        <div className="col-md-12 text-center">
                            <Button variant="primary" type="submit">
                                Save
                            </Button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    )
}

export default AddNewDevice