import axios from "axios"

const getAuth = () => {
    return {headers: {Authorization: localStorage.getItem('token')}}
}

const Backend = {
    get: async (url, done, error) => await axios
        .get(url, getAuth())
        .then(res => done && done(res))
        .catch(err => error && error(err)),

    post: (url, body, done, error) => axios
        .post(url, body, getAuth())
        .then(res => done && done(res))
        .catch(err => error && error(err)),

    put: (url, body, done, error) => axios
        .put(url, body, getAuth())
        .then(res => done && done(res))
        .catch(err => error && error(err))
}

export default Backend
