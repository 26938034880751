import {BrowserRouter} from 'react-router-dom'
import React from 'react'
import NavBar from '../Parts/NavBar'
import Menu from '../Parts/Menu'
import Content from '../Parts/Content'

export default function MainPage({logout}) {
  return (
    <div className="App">
      <BrowserRouter>
        <NavBar logout={logout}/>
        <Menu />
        <Content />
      </BrowserRouter>
    </div>
  )
}
