import {Row, Col, Card, Container} from "react-bootstrap"
import Chart from "../Elements/Chart"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChartSimple} from '@fortawesome/free-solid-svg-icons'
import {useState, useEffect} from 'react'
import Backend from "../Utils/Backend"

const Dashboard = () => {
    const [data, setData] = useState({})
    const [showErrorsChart, setShowErrorsChart] = useState(true)

    useEffect(() => {
        Backend.get(`${process.env.REACT_APP_BASE_URL}/dashboard`, res => setData(res.data))
    }, []) 

    const getChartSchema = () => {
        return showErrorsChart ? {
            labels: ['INFO', 'WARN', 'CRIT'],
            label: 'Errors in ...',
            data: [3, 5, 6]
        } : {
            labels: ['ENGINE_MOVED_FORWARD', 'ENGINE_MOVED_BACKWARD', 'ENGINE_STOPPED'],
            label: 'Movements in ...',
            data: [23, 43, 65]
        }
    }

    return (
        <Container>
            <Row>
                <Chart schema={getChartSchema()} />
            </Row>
            <Row>
                <Col>
                    <Card>
                        <Card.Header as="h5" onClick={() => setShowErrorsChart(true)}>
                            Error messages&nbsp;&nbsp;
                            <FontAwesomeIcon icon={faChartSimple} />
                        </Card.Header>
                        <Card.Body>
                            {data.ecuErrorTotals && data.ecuErrorTotals.map((errorData, val) =>
                                <Card key={val}>
                                    <Card.Body>
                                        {errorData._id} count: {errorData.count}
                                    </Card.Body>
                                </Card>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Header as="h5" onClick={() => setShowErrorsChart(false)}>
                            Movement messages&nbsp;&nbsp;
                            <FontAwesomeIcon icon={faChartSimple} />
                        </Card.Header>
                        <Card.Body>
                            {data.movementsTotals && data.movementsTotals.map((movementData, val) =>
                                <Card key={val}>
                                    <Card.Body>
                                        {movementData._id} count: {movementData.count}
                                    </Card.Body>
                                </Card>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default Dashboard
