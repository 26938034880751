export const dateFilterParams = {
    comparator: function (filterLocalDateAtMidnight, cellValue) {
        if (cellValue == null)
            return -1
        var cellDate = new Date(cellValue)
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) 
            return 0
        if (cellDate < filterLocalDateAtMidnight)
            return -1
        if (cellDate > filterLocalDateAtMidnight)
            return 1
    },
    browserDatePicker: true
}

export function dateFormatter(params) {
    return params.data.timeStamp.indexOf('-') === -1 ? params.data.timeStamp : new Date(params.data.timeStamp).toLocaleString("hu-HU")
}