import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import MainPage from './Pages/MainPage'
import LoginPage from './Pages/LoginPage'
import {useState, useEffect} from 'react'
import Auth from "./Utils/Auth"

function App() {
  const [loggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    Auth.setLoggedIn(setLoggedIn)
  }, []) 

  return (
    <>
      {loggedIn ? <MainPage logout={Auth.logout}/> : <LoginPage login={Auth.login}/>}
    </>
    )
}

export default App
