import {Switch, Redirect} from 'react-router-dom'
import ProtectedRoute from '../Parts/ProtectedRoute'
import Dashboard from '../Content/Dashboard'
import Device from '../Content/Device'
import Devices from '../Content/Devices'
import EditDevice from '../Content/EditDevice'
import AddNewDevice from "../Content/AddNewDevice"

export default function Content() {
    
    return (
        <div className="mainPage">
            <Switch>
            <ProtectedRoute path="/dashboard" component={Dashboard} />
            <ProtectedRoute path="/devices/:id" component={Device} />
            <ProtectedRoute path="/devices" component={Devices} edit={false} />
            <ProtectedRoute path="/manage/new" component={AddNewDevice} />
            <ProtectedRoute path="/manage/:id" component={EditDevice} />
            <ProtectedRoute path="/manage" component={Devices} edit={true} />            
            <ProtectedRoute path="/" component={Redirect} to="/dashboard"/>
            </Switch>
        </div>
    )
}
