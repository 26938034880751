import React from 'react'
import Backend from "../Utils/Backend"
import {useParams} from "react-router"
import {useEffect, useState} from "react"
import DeviceHead from "../Elements/DeviceHead"
import DataTable from "../Elements/DataTable"

export default function Device() {
  const {id} = useParams()
  const [device, setDevice] = useState({})
  const [tableList, setTableList] = useState([])

  useEffect(() => {
    Backend.get(`${process.env.REACT_APP_BASE_URL}/xrayDevice/${id}`, res => {
      setDevice(res.data)
      setTableList(res.data.type === "Cobra" ? cobraList : fcList)
    })
  }, [id])

  return (
    <>
      <ul className='ListGroup'>
        <DeviceHead device={device} />
        {tableList.map(table => {
          return <DataTable key={table.url} config={table}/>
        })}
      </ul>
    </>
  )
}

const prio = {field: "priority", name: "Priority", width: 90}
const time = {field: "timeStamp", name: "Timestamp", width: 170}
const action = {field: "action", name: "Action", width: 250}
const value = {field: "value", name: "Value"}
const type = {field: "type", name: "Type", width: 300}
const message = {field: "message", name: "Message"}
const worktime = {field: "engineTotalWorkTimeInHour", name: "Worktime [h]", width: 150}
const motion = {field: "engineTotalMotionInMeter", name: "Motion [m]"}
const avgPower = {field: "avgPowerConsumption", name: "Average Power Consumption"}
const imgs = {field: "imgs", name: "Appendix", width: 200}

const cobraList = [
  { url: "battery", title: "Battery Information", columns:[prio, time, action, value]},
  { url: "enginePowerInfos", title: "Engine Power Information", columns:[prio, time, worktime, motion]},
  { url: "errors", title: "Errors", columns:[prio, time, type, message]},
  { url: "movements", title: "Movements", columns:[prio, time, action]},
  { url: "powerConsumptions", title: "Power Consumption", columns:[prio, time, type, avgPower]},
  { url: "generator", title: "Generator", columns:[prio, time, action, value]},
  { url: "remoteControl", title: "Remote Control", columns:[prio, time, action, value]},
  { url: "screen", title: "Screen Information", columns:[prio, time, action]}
]
const fcList = [
  { url: "errors", title: "Errors", columns:[prio, time, type, message]},
  { url: "serviceHistory", title: "Service history", columns:[time, message, imgs]}
]
