import { Nav, Navbar, Button } from "react-bootstrap"
import {useState, useEffect} from 'react'
import Backend from "../Utils/Backend"

const NavBar = ({logout}) => {
    const [backendVersion, setBackendVersion] = useState("UNKNOWN")
    const [backendDatabase, setBackendDatabase] = useState("UNKNOWN")

    useEffect(() => {
        Backend.get(`${process.env.REACT_APP_BASE_URL}/app/version`, res => {
            setBackendVersion(res.data.VERSION)
            setBackendDatabase(res.data.DB)
        })
    }, []) 

    return (
        <div>
            <Navbar className="fixed-top" expand="lg">
                <Navbar.Brand href="#home">
                    <img src="/control-x.png"
                        width="220"
                        height="50"
                        alt="Control-X"
                    />
                </Navbar.Brand>
                <Nav style={({ marginLeft: '0.8rem' })}>
                    Version:&nbsp; <b>{process.env.REACT_APP_VERSION}</b>
                </Nav>
                <Nav style={({ marginLeft: '0.8rem' })}>
                    Backend:&nbsp; <b>{process.env.REACT_APP_BASE_URL}</b>
                </Nav>
                <Nav style={({ marginLeft: '0.8rem' })}>
                    Version:&nbsp; <b>{backendVersion}</b>
                </Nav>
                <Nav style={({ marginLeft: '0.8rem' })}>
                    Database:&nbsp; <b>{backendDatabase}</b>
                </Nav>
                <Nav className="ms-auto" style={({ marginLeft: '0.8rem' })}>
                    <Button className="navBtn" onClick={logout}>Logout</Button>
                </Nav>
            </Navbar>
        </div>
    )
}

export default NavBar
