import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faGear, faChartLine, faLaptopFile, faXRay} from '@fortawesome/free-solid-svg-icons'
import {Link} from "react-router-dom"
import {useLocation} from "react-router-dom"
import {useState, useEffect} from 'react'
import {getName} from '../Utils/DeviceMap'

const Menu = () => {
    const currentLocation = useLocation().pathname
    const [items, setItems] = useState(defaultItems)

    useEffect(() => {
        const parts = currentLocation.split('/')
        const item = {
            name: getName(parts[2]),
            link: `/${(parts[1])}/${parts[2]}`,
            icon: <FontAwesomeIcon icon={faXRay} />,
            class: 'childRow'
        }
        const newItems = []
        defaultItems.forEach(it => {
            newItems.push(it)
            if(item.name && it.link.endsWith(parts[1]))
                newItems.push(item)
        })
        setItems(newItems)
    }, [currentLocation]) 

    const createMenuItem = (item, val) => (
        <Link to={item.link} key={val}>
            <li className={item.class} id={currentLocation === item.link ? 'active' : ''}>
                <div id="icon">{item.icon}</div>
                <div id="title">{item.name}</div>
            </li>
        </Link>)

    return (
        <div className="Menu">
            <ul className="MenuItems">
                {items.map((item, val) => createMenuItem(item, val))}
            </ul>
        </div>
    )    
}

export default Menu

const defaultItems = [
    {
        name: 'Dashboard',
        link: '/dashboard',
        icon: <FontAwesomeIcon icon={faChartLine} />,
        class: 'row'
    },
    {
        name: 'Manage',
        link: '/manage',
        icon: <FontAwesomeIcon icon={faGear} />,
        class: 'row'
    },
    {
        name: 'Devices',
        link: '/devices',
        icon: <FontAwesomeIcon icon={faLaptopFile} />,
        class: 'row'
    }
]
