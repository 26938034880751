import React, { useState } from 'react'
import { useForm } from "react-hook-form"
import { Form, Button, Row, Col } from "react-bootstrap"
import "../App.css"
import Backend from "../Utils/Backend"

export default function LoginPage({login}) {
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [isError, setIsError] = useState(false)
    const {handleSubmit} = useForm()

    const tryLogin = () => {
        const url = `${process.env.REACT_APP_BASE_URL}/auth/admin/login`
        const body = {
            email: email,
            password: password
        }
        Backend.post(url, body, 
            res => login(res.data.access_token), 
            err => setIsError(true))
    }

    return (
        <div className="login">
            <Row>
                <Col md="auto">
                    <div className="logo">
                        <img src="/control-x.png"
                            width="440"
                            height="100"
                            alt="Control-X"
                        />
                    </div>
                    <Form onSubmit={handleSubmit(() => tryLogin())}>
                        {isError && <Form.Text style={{ color: 'red' }}>Unsuccesfull login! </Form.Text>}
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                        </Form.Group>
                        <Row>
                            <Col align='center'>
                                <Button type="submit" variant="primary">Login</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    )
}
