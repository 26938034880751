import { Row } from "react-bootstrap"

const ImageThumbnails = ({ imgs }) => {
    return (
        <>
            {imgs.map((img, key) => (
                <Row key={key}>
                    <img className="photo" src={'data:image/png;base64, ' + img} alt="..." />
                </Row>
            ))}
        </>
    )
}

export default ImageThumbnails
