import Table from "../Elements/Table"
import "../App.css"
import {useParams} from "react-router"
import {useEffect, useState} from "react"
import Backend from "../Utils/Backend"
import {dateFilterParams, dateFormatter} from "../Utils/DateUtils"
import ImageViewModal from "../Elements/ImageViewModal"
import ImageThumbnails from "../Elements/ImageThumbnails"

const DataTable = ({config}) => {
    const {id} = useParams()
    const [data, setData] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [selectedEntry, setSelectedEntry] = useState({})
    const isServiceHistory = config.url === "serviceHistory"

    useEffect(() => {
        Backend.get(`${process.env.REACT_APP_BASE_URL}/${config.url}/${id}`, res => setData(res.data))
    }, [config, id])
    
    const openModal = (entry) => {
        setSelectedEntry(entry)
        setShowModal(true)
    }
    const closeModal = () => setShowModal(false)

    const cellRenderer = {
        priority: params => <div className={params.value}>{params.value}</div>,
        message: params => <div className="multiline">{params.data.message}</div>,
        imgs: params => <div onClick={() => openModal(params.data)}><ImageThumbnails imgs={params.data.imgs} /></div>,
        value: params => <div>{JSON.stringify(params.value)}</div>
    }

    const columns = []
    config.columns.forEach(col => {
        const newColumn = {
            headerName: col.name, 
            field: col.field, 
            sortable: col.field !== "imgs", 
            autoHeight: isServiceHistory, 
            width: col.width, 
            maxWidth: col.width, 
            flex: col.flex
        }
        if(col.field === "timeStamp"){
            newColumn.filter = "agDateColumnFilter"
            newColumn.filterParams = dateFilterParams
            newColumn.valueFormatter = dateFormatter
        }
        if(cellRenderer.hasOwnProperty(col.field))
            newColumn.cellRenderer = cellRenderer[col.field]
        columns.push(newColumn)
    })

    return (
    <>
        {data.length > 0 && 
        <>
            <Table data={data} column={columns} title={config.title}/>
            {isServiceHistory && <ImageViewModal show={showModal} closeModal={closeModal} selected={selectedEntry}/>}
        </>
        }
    </>)
}

export default DataTable
