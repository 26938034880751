const setExpiry = () => localStorage.setItem('expiry', Date.now() + 60 * 60 * 1000)
var setLoggedInState

const Auth = {
    setLoggedIn: setLoggedIn => {
        setLoggedInState = setLoggedIn
        setLoggedInState(Auth.checkAuthentication())
    },

    login: access_token => {
        localStorage.setItem('token', 'Bearer ' + access_token)
        setExpiry()
        setLoggedInState(true)
    },

    logout: () => {
        localStorage.removeItem('token')
        localStorage.removeItem('expiry')
        setLoggedInState(false)
    },
    
    checkAuthentication: () => {
        const expiry = localStorage.getItem('expiry')
        const now = Date.now()
        const res = expiry > now
        if(res) 
            setExpiry()
        else
            Auth.logout()
        return res
    }
}

export default Auth
