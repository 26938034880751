import {Form, Button, Row, Col, Container, Card, ListGroup} from "react-bootstrap"
import {useParams} from "react-router"
import {useForm} from "react-hook-form"
import {useEffect, useState} from "react"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEdit, faTrashCan} from '@fortawesome/free-solid-svg-icons'
import Backend from "../Utils/Backend"

const EditDevice = () => {
    const {id} = useParams()
    const [device, setDevice] = useState({})
    const [emailError, setEmailError] = useState("")
    const [serialError, setSerialError] = useState("")
    const [aliasError, setAliasError] = useState("")
    const {register, getValues, setValue} = useForm()
    const updateUrl = `${process.env.REACT_APP_BASE_URL}/xrayDevice/update`

    useEffect(() => {
        Backend.get(`${process.env.REACT_APP_BASE_URL}/xrayDevice/${id}`, res => setDevice(res.data))
    }, [id])

    const addEmail = () => {
        const newEmail = getValues("email")
        if (device.emails.indexOf(newEmail) > -1)
            setEmailError("Email already added!")
        else if (newEmail === '')
            setEmailError("Email field is empty!")
        else {
            device.emails.push(newEmail)
            Backend.put(updateUrl, device, res => {
                setDevice(res.data)
                setValue("email", "")
                setEmailError("")
            }, err => console.log(err))
        }
    }

    const handleDelete = (val) => {
        device.emails.splice(val, 1)
        Backend.put(updateUrl, device, res => setDevice(res.data))
    }

    const editSerialNum = () => {
        const newSerial = getValues("serial")
        if (newSerial === '')
            setSerialError("Serial number field is empty!")
        else {
            device.serialNumber = newSerial
            Backend.put(updateUrl, {
                _id: device._id,
                serialNumber: newSerial
            }, res => {
                setDevice(res.data)
                setSerialError("")
            }, err => setSerialError("Serial number invalid!"))
        }
    }

    const editAlias = () => {
        const newAlias = getValues("alias")
        if (newAlias === '')
            setAliasError("Alias field is empty!")
        else {
            device.alias = newAlias
            Backend.put(updateUrl, {
                _id: device._id,
                alias: newAlias
            }, res => {
                setDevice(res.data)
                setAliasError("")
            }, err => setAliasError("Alias invalid!"))
        }
    }

    return (
        <Container>
            <div>
                <Card>
                    <Card.Body>
                        <h4>Edit {device.alias}</h4>
                    </Card.Body>
                </Card>
            </div>
            <br />
            <div>
                <Row>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Form.Label>Serial number</Form.Label>
                                <Row>
                                    <Col>
                                        <Form.Control defaultValue={device.serialNumber} {...register("serial")} />
                                        {serialError && <Form.Text style={{ color: 'red' }}>{serialError}</Form.Text>}
                                    </Col>
                                    <Col xs={2}>
                                        <Button onClick={() => editSerialNum()}><FontAwesomeIcon icon={faEdit} /></Button>
                                    </Col>
                                </Row>
                                <br />
                                <Form.Label>Alias</Form.Label>
                                <Row>
                                    <Col>
                                        <Form.Control defaultValue={device.alias} {...register("alias")} />
                                        {aliasError && <Form.Text style={{ color: 'red' }}>{aliasError}</Form.Text>}
                                    </Col>
                                    <Col xs={2}>
                                        <Button onClick={() => editAlias()}><FontAwesomeIcon icon={faEdit} /></Button>
                                    </Col>
                                </Row>
                                <br />
                                <Form.Label>Registration date</Form.Label>
                                <Form.Control type="text" placeholder={new Date(device.registeredAt).toLocaleString("hu-HU")} readOnly />
                                <br />
                                <Form.Label>Last login date</Form.Label>
                                <Form.Control type="text" placeholder={new Date(device.lastLogin).toLocaleString("hu-HU")} readOnly />
                                <br />
                                <Form.Label>Emails</Form.Label>
                                <ListGroup>
                                    {device.emails && device.emails.map((email, val) => (
                                        <Row key={val}>
                                            <Col>
                                                <ListGroup.Item>{email} </ListGroup.Item>
                                            </Col>
                                            <Col>
                                                <Button variant="danger" onClick={() => handleDelete(val)}><FontAwesomeIcon icon={faTrashCan} /></Button>
                                            </Col>
                                        </Row>
                                    ))}
                                </ListGroup>
                                <br />
                                <Row>
                                    <Col>
                                        <Form.Control {...register("email")}></Form.Control>
                                        {emailError && <Form.Text style={{ color: 'red' }}>{emailError}</Form.Text>}
                                    </Col>
                                    <Col xs={3}>
                                        <Button onClick={() => addEmail()}>Add</Button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Container>
    )
}

export default EditDevice
