import React from 'react'
import {Row, Col, Card} from "react-bootstrap"
import {useState} from "react"

const DeviceHead = ({device}) => {
    const [visible, setVisible] = useState(false)

    const onClick = () => setVisible(!visible)

    return (
        <>
            <Card>
                <Card.Header as="h2" onClick={onClick}>
                    <b>{device.alias}</b> [{device.type}] {device.serialNumber}
                </Card.Header>
                {visible && <Card.Body>
                    <Row>
                        <Col><b>Registered at: </b></Col>
                        <Col>{new Date(device.registeredAt).toLocaleString("hu-HU")}</Col>
                    </Row>
                    <Row>
                        <Col><b>Last login: </b></Col>
                        <Col>{new Date(device.lastLogin).toLocaleString("hu-HU")}</Col>
                    </Row>
                </Card.Body>}
            </Card>
        </>
    )
}

export default DeviceHead
