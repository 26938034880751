import {AgGridReact} from "ag-grid-react"
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import 'ag-grid-community/dist/styles/ag-grid.css'
import {useEffect, useState} from "react"
import "react-datepicker/dist/react-datepicker.css"
import "../App.css"
import {Card} from "react-bootstrap"

const Table = ({ data, column, title }) => {
    const [gridApi, setGridApi] = useState()
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [visible, setVisible] = useState(false)
    const height = data.length * 42 + 50
    const count = data.length
    const defColumnDefs = { flex: 1 }

    const onGridReady = (params) => {
        setGridApi(params)
    }
    useEffect(() => {
        const getFilterType = () => {
            if (startDate !== '' && endDate !== '') return 'inRange'
            else if (startDate !== '') return 'greaterThan'
            else if (endDate !== '') return 'lessThan'
        }
        if (gridApi) {
            if (startDate !== '' && endDate !== '' && startDate > endDate) {
                alert("Start date should be before end date")
                setEndDate('')
            } else {
                var dateFilterComponent = gridApi.api.getFilterInstance('timeStamp')
                dateFilterComponent.setModel({
                    type: getFilterType(),
                    dateFrom: startDate ? startDate : endDate,
                    dateTo: endDate,
                })
                gridApi.api.onFilterChanged()
            }
        }
    }, [startDate, endDate, gridApi])

    const onClick = () => {
        setVisible(!visible)
    }

    return (
        <>
            <Card>
                <Card.Header as="h3" onClick={onClick}>
                    <b>{title}</b> [{count}]
                </Card.Header>
                {visible && <Card.Body>
                    <div className="ag-grid-table">
                        <div className="ag-theme-alpine" style={{height:height}}>
                            <div>
                                From : <input type="date" value={startDate} onChange={e => setStartDate(e.target.value)} />&nbsp;&nbsp;
                                To : <input type="date" value={endDate} onChange={e => setEndDate(e.target.value)} />
                            </div>
                            <br />
                            <AgGridReact
                                rowData={data}
                                columnDefs={column}
                                defaultColDef={defColumnDefs}
                                onGridReady={onGridReady} />
                        </div>
                    </div>
                </Card.Body>}
            </Card>
        </>
    )
}
export default Table
